<template>
  <div class="bitcoin-pets">
    <div id="bitcoin-page">
      <div class="page1">
        <div class="text-bitcoin-pets">
          The 1st pets on .bitmap
        </div>
        <div class="text-bitcoin-pets">
          Pioneer the use of Recursive Inscriptions
        </div>
        <div class="text-bitcoin-pets">
          Genesis digital artifacts in BTC metaverse
        </div>
      </div>
      <div class="page2">
        <div  @mouseleave="settingCarousel" @mouseenter="suspendCarousel" class="img-box">
          <div class="container hide">
            <div class="img-action">
              <img src="@/assets/images/dog.png" alt="">
            </div>
          </div>
          <div class="container" id="all-transition" :style="{left: `${containerLeft}px`}">
            <div :class="`tiem-img ${containerItemPage === -3 ? 'img-action' : ''}`">
              <img src="@/assets/images/dog1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === -2 ? 'img-action' : ''}`">
              <img src="@/assets/images/horse1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === -1 ? 'img-action' : ( directionA ? 'no-transition' : '')}`">
              <img src="@/assets/images/bird1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 0 ? ( directionA ? 'img-action no-transition' : 'img-action') : ( directionA ? 'no-transition' : '')}`">
              <img src="@/assets/images/cat1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 1 ? 'img-action' : ''}`">
              <img src="@/assets/images/lion1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 2 ? 'img-action' : ''}`">
              <img src="@/assets/images/dog1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 3 ? 'img-action' : ''}`">
              <img src="@/assets/images/horse1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 4 ? ( directionA ? 'img-action no-transition' : 'img-action') : ( directionA ? 'no-transition' : '')}`">
              <img src="@/assets/images/bird1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 5 ? 'img-action' : ( directionA ? 'no-transition' : '')}`">
              <img src="@/assets/images/cat1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 6 ? 'img-action' : ''}`">
              <img src="@/assets/images/lion1.png" alt="">
            </div>
            <div :class="`tiem-img ${containerItemPage === 7 ? 'img-action' : ''}`">
              <img src="@/assets/images/dog1.png" alt="">
            </div>
          </div>
          <div class="left-arr">
            <img src="@/assets/images/left-arr.png" alt="" @click.stop="setContainerItemPage(false)">
          </div>
          <div class="right-arr">
            <img src="@/assets/images/right-arr.png" alt="" @click.stop="setContainerItemPage(true)">
          </div>
        </div>
      </div>
    </div>
    <div class="ordinals-footer"></div>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch, onBeforeUnmount } from 'vue'
export default {
  name: 'BitcoinPets',
  setup() {
    const mouse = ref(false)
    const direction = ref(1)
    const page = ref(1)
    const containerLeft = ref(-597)
    const containerItemPage = ref(1) 
    const timer = ref(null)
    const directionA = ref(false)
    const containerClick = ref(false)


    const scrollFunc = (e) => {
      const bitcoinPage = document.getElementById('bitcoin-page')
      const { clientHeight, scrollTop, scrollHeight } = document.documentElement;
      if (mouse.value) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      e = e || window.event;
      if ((e.wheelDelta >= 0 || e.detail < 0)) { // IE/Opera/Chrome/
        if (+scrollTop !== 0) {
          return;
        }
        if (page.value <= 1) {
          return;
        }
        document.documentElement.classList.add('disable-scrolling');
        bitcoinPage.classList.remove('page-box')
        mouse.value = true;
        direction.value = 2;
        page.value = page.value - 1;
      } else {
        if (+clientHeight + +scrollTop + 2 < +scrollHeight) {
          return;
        }
        if (page.value >= 2) {
          return;
        }
        document.documentElement.classList.add('disable-scrolling');
        bitcoinPage.classList.add('page-box')
        mouse.value = true;
        direction.value = 1;
        page.value = page.value + 1;
      }
      setTimeout(() => {
        document.documentElement.classList.remove('disable-scrolling');
        mouse.value = false;
      }, 1500);
    }

    onBeforeMount(()=>{
      /* 注册事件 */
      if (document.addEventListener) {
        page.value = 1
        // document.body.addEventListener('DOMMouseScroll', scrollFunc, false);
        document.body.addEventListener('wheel', scrollFunc, false);
      } else {
        // W3C
        // eslint-disable-next-line no-multi-assign
        window.onmousewheel = document.onmousewheel = scrollFunc;// IE/Opera/Chrome
      }
    })

    onBeforeUnmount(()=>{
      clearInterval(timer.value)
      if(page.value === 2) {
        const bitcoinPage = document.getElementById('bitcoin-page')
        page.value = 1
        bitcoinPage.classList.remove('page-box')
      }
      document.documentElement.classList.remove('disable-scrolling');
      const { body } = document;
      body.classList.remove('body-background');
      if (document.addEventListener) {
        // document.body.removeEventListener('DOMMouseScroll', scrollFunc);
        document.body.removeEventListener('wheel', scrollFunc);
      }// W3C
    })

    watch(()=>{
      containerLeft.value = -597 + containerItemPage.value * -422
    }, containerItemPage.value)
    
    watch(()=>{
      if(page.value === 2) {
        settingCarousel()
      }
    }, page.value)

    const setContainerItemPage = (e) => {
      if(containerClick.value) {
        return
      }
      const allTransition = document.getElementById('all-transition')
      allTransition.classList.add('all-transition')
      containerClick.value = true
      directionA.value = false
      if(e) {
        containerItemPage.value = containerItemPage.value + 1
      } else {
        containerItemPage.value = containerItemPage.value - 1
      }
      setTimeout(() => {
        allTransition.classList.remove('all-transition')
        if(containerItemPage.value === 5) {
          directionA.value = true
          containerItemPage.value = 0
        }
        if(containerItemPage.value === -1) {
          directionA.value = true
          containerItemPage.value = 4
        }
        containerClick.value = false
      }, 1000);
    }

    // 轮播动画自动播放
    const settingCarousel = () => {
      clearInterval(timer.value)
      timer.value = setInterval(() => {
        const allTransition = document.getElementById('all-transition')
        allTransition.classList.add('all-transition')
        containerItemPage.value = containerItemPage.value + 1
        directionA.value = false
      setTimeout(() => {
        allTransition.classList.remove('all-transition')
        if(containerItemPage.value === 5) {
          directionA.value = true
          containerItemPage.value = 0
        }
        if(containerItemPage.value === -1) {
          directionA.value = true
          containerItemPage.value = 4
        }
        containerClick.value = false
      }, 1000);
      }, 4500);
    }

    const suspendCarousel = () => {
      clearInterval(timer.value)
    }

    return {
      scrollFunc,
      direction,
      containerLeft,
      containerItemPage,
      setContainerItemPage,
      directionA,
      settingCarousel,
      suspendCarousel
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bitcoin-pets {
  position: relative;
  height: 625px;
  padding: 191px 180px 150px;
  color: #000;
  font-size: 34.64px;
  font-weight: 400;
  overflow: hidden;
}
#bitcoin-page {
  transition: all 1s;
}
.page-box {
  transform: translateY(-700px);
}
.page1 {
  transform: translateY(50px);
  position: relative;
}
.ordinals-footer {
  position: absolute;
  bottom: 0;
  left: 323px;
  width: 100%;
  height: 100px;
  background-image: url("~@/assets/images/footer-img.png");
  background-position: bottom 0px left 0px;
  background-size: 500px 91px;
  background-repeat: repeat no-repeat;
}
.page2 {
  transform: translateY(100px);
  height: 600px;
  display: flex;
  width: 1911px;
  left: -180px;
  align-items: center;
  margin-top: 240px;
  margin-bottom: 240px;
  position: relative;
}
.img-box {
  width: 1911px;
  position: relative;
}
.all-transition {
  transition: all 1s;
}
.img-box .container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 474px;
}
.left-arr, .right-arr {
  position: absolute;
  top: 176px;
  cursor: pointer;
}
.left-arr {
  left: 573px;
}
.right-arr {
  right: 573px;
}
.img-box .hide {
  position: relative;
  opacity: 0;
  z-index: -2;
}
.container .img-action {
  width: 474px;
  height: 474px;
  opacity: 1;
}
.no-transition {
  transition: none !important;
}
.tiem-img {
  width: 322px;
  height: 322px;
  opacity: 0.7;
  padding: 0 50px;
  transition: all 1s;
}
.tiem-img img {
  width: 100%;

}
.text-bitcoin-pets {
  margin-bottom: 135px;
}
</style>
